import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import AniLink from "gatsby-plugin-transition-link/AniLink";

class ThankYou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;
    const spaces = data.contentfulSpaces;

    return (
      <Layout>
        <Helmet>
          <title>Thank You - Gracehouse Church Logan</title>
          <meta
            property="og:title"
            content="Thank You - Gracehouse Church Logan"
          />
          <meta name="robots" content="noindex"></meta>
          <meta
            name="description"
            content="We have received your expression of interest and will get back to you shortly. If you have any additional questions, please feel free to contact us below."
          />
          <meta
            property="og:description"
            content="We have received your expression of interest and will get back to you shortly. If you have any additional questions, please feel free to contact us below."
          />
        </Helmet>
        <section className="thanks-header">
          <div className="container container-sm">
            <h1>
              Thank <span>You!</span>
            </h1>
            <p>
              We have received your expression of interest and will get back to
              you shortly.
            </p>
            <p>
              If you have any additional questions, please feel free to contact
              us below.
            </p>
            <a href={`mailto:${spaces.email}`} className="link">
              {spaces.email}
            </a>
            <AniLink className="button" fade to="/">
              Return Home
            </AniLink>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ThankYou;

export const pageQuery = graphql`
  query ThankYouQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSpaces {
      description {
        description
      }
      email
      spaceList {
        description
        features
        id
        title
        images {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
